var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"agency-add-wrapper"},[_c('b-modal',{ref:'add-agency-'+_vm.indexref,attrs:{"id":'add-agency-'+_vm.indexref,"centered":"","hide-footer":"","size":"lg","title":" Agenzia"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('b-card',[_c('validation-observer',{ref:"AgencyRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.saveAgency())}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Ragione Sociale")]),_c('validation-provider',{attrs:{"rules":"required","name":"CardName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Ragione Sociale"},model:{value:(_vm.Agency.company),callback:function ($$v) {_vm.$set(_vm.Agency, "company", $$v)},expression:"Agency.company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Partita IVA")]),_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Partita IVA"},model:{value:(_vm.Agency.piva),callback:function ($$v) {_vm.$set(_vm.Agency, "piva", $$v)},expression:"Agency.piva"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Indirizzo","label-for":"fh-desc"}},[_c('b-form-textarea',{attrs:{"id":"fh-desc","placeholder":"Descrizione","rows":"3","max-rows":"8"},model:{value:(_vm.Agency.address),callback:function ($$v) {_vm.$set(_vm.Agency, "address", $$v)},expression:"Agency.address"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Stato","label-for":"country"}},[_c('v-select',{staticClass:"fixborder",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":true,"options":_vm.countries,"label":"name","index":"code","reduce":function (val) { return val.code; },"input-id":"country"},model:{value:(_vm.Agency.country),callback:function ($$v) {_vm.$set(_vm.Agency, "country", $$v)},expression:"Agency.country"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Provincia")]),_c('validation-provider',{attrs:{"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Provincia"},model:{value:(_vm.Agency.provincia),callback:function ($$v) {_vm.$set(_vm.Agency, "provincia", $$v)},expression:"Agency.provincia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Citta")]),_c('validation-provider',{attrs:{"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Citta"},model:{value:(_vm.Agency.citta),callback:function ($$v) {_vm.$set(_vm.Agency, "citta", $$v)},expression:"Agency.citta"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Cap")]),_c('validation-provider',{attrs:{"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Cap"},model:{value:(_vm.Agency.cap),callback:function ($$v) {_vm.$set(_vm.Agency, "cap", $$v)},expression:"Agency.cap"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Telefono")]),_c('validation-provider',{attrs:{"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Telefono"},model:{value:(_vm.Agency.telefono),callback:function ($$v) {_vm.$set(_vm.Agency, "telefono", $$v)},expression:"Agency.telefono"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Cellulare")]),_c('validation-provider',{attrs:{"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Cellulare"},model:{value:(_vm.Agency.cellulare),callback:function ($$v) {_vm.$set(_vm.Agency, "cellulare", $$v)},expression:"Agency.cellulare"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("E-Mail")]),_c('validation-provider',{attrs:{"rules":"required","name":"Mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Mail"},model:{value:(_vm.Agency.companyEmail),callback:function ($$v) {_vm.$set(_vm.Agency, "companyEmail", $$v)},expression:"Agency.companyEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Referente")]),_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Referente"},model:{value:(_vm.Agency.name),callback:function ($$v) {_vm.$set(_vm.Agency, "name", $$v)},expression:"Agency.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("E-Mail Referente")]),_c('validation-provider',{attrs:{"rules":"required","name":"Mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Mail"},model:{value:(_vm.Agency.email),callback:function ($$v) {_vm.$set(_vm.Agency, "email", $$v)},expression:"Agency.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Contatto Referente")]),_c('validation-provider',{attrs:{"rules":"required","name":"contact"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Contatto"},model:{value:(_vm.Agency.contact),callback:function ($$v) {_vm.$set(_vm.Agency, "contact", $$v)},expression:"Agency.contact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s((_vm.Agency.Id)? "Salva" : "Aggiungi")+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":_vm.reset}},[_vm._v(" Reset ")])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }